import { Image, Text } from '@belong/ui';
import css from './social-proof.module.css';

const SOCIAL_PROOF_STATS = [
  {
    count: <RatingStat rating="4.7" />,
    label: 'Google',
  },
  {
    count: <RatingStat rating="4.9" />,
    label: 'Thumbtack',
  },
];

const SOCIAL_PROOF_LOGOS = [
  {
    src: '/logos/businessinsider_logo_dark_gray.svg',
    alt: 'Business Insider Logo',
    body: 'BI’s 2022 Most Promising Consumer\u00a0Startups',
  },
  {
    src: '/logos/techcrunch_logo_dark_gray.svg',
    alt: 'TechCrunch Logo',
    body: '“80M to take the pain out of\u00a0rentals”',
  },
  {
    src: '/logos/sfist_logo_dark_gray.svg',
    alt: 'SFist Logo',
    body: 'Best Property Managers in San\u00a0Francisco',
  },
  {
    src: '/logos/cbinsights_dark_gray.svg',
    alt: 'CB Insights Logo',
    body: 'CB Insights Fintech 250 in the Real Estate\u00a0category',
  },
];

export function SocialProof() {
  return (
    <div className="text-dark-gray grid grid-cols-2 lg:grid-cols-3 gap-x-xs gap-y-lg lg:gap-xl text-center">
      {SOCIAL_PROOF_STATS.map(({ count, label }) => (
        <div key={label} style={{ height: 85 }} className="flex flex-col justify-center items-center">
          {typeof count === 'string' ? (
            <Text variant="h2" fontWeight="semibold">
              {count}
            </Text>
          ) : (
            count
          )}
          <Text className="text-p1 lg:text-body mt-xs">{label}</Text>
        </div>
      ))}

      {SOCIAL_PROOF_LOGOS.map(({ src, alt, body }) => (
        <div key={src} className="flex flex-col justify-center items-center">
          <div style={{ width: '150px', height: '44px' }} className="mb-xs">
            <Image src={src} alt={alt} />
          </div>
          <Text variant="p1" className={css.bodyContainer}>
            {body}
          </Text>
        </div>
      ))}
    </div>
  );
}

function RatingStat({ rating }) {
  return (
    <div className="flex items-center justify-center gap-2xs ">
      <Text variant="h2" fontWeight="semibold">
        {rating}
      </Text>
      <div style={{ width: '25px' }}>
        <Image src="/icons/yellow_star.svg" alt="Yellow Star" />
      </div>
    </div>
  );
}
